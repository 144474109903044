import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import "../styles/modal.css";
import tw from 'twin.macro'
import {
  ButtonStyle,
  CopyLGStyle,
  CopyStyle,
  HeaderStyle,
  LinkButton,
  ScoreStyle,
  SubheadSMStyle,
} from '../components/UI'
import { graphql, useStaticQuery } from 'gatsby'
import appStore from '../images/app-store.svg'
import googlePlay from '../images/google-play.svg'

const ThankyouModal = ({ setIsOpen }) => {
  const globalQuery = useStaticQuery(graphql`
    {
      contentfulGlobalSettings(pageId: { eq: "Globals" }) {
        socials {
          logo {
            title
            gatsbyImageData
          }
          url
        }
        iosDownloadLink
        androidDownloadLink
      }
    }
  `)
  const globalData = globalQuery.contentfulGlobalSettings
  console.log('globalData', globalData)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const topForm = useForm()
  const bottomForm = useForm()
  return (
    <>
      <div className='darkBG' onClick={() => setIsOpen(false)} />
      <div className='centered'>
        <div className='modal'>
          <div className='modalHeader'>
            <span className='close-modal' onClick={() => setIsOpen(false)}>X</span>
            <h5 className='heading-modal'></h5>
          </div>

          <div className='modal-container' tw='text-center'>


            <div>
              <h2 tw='mb-[24px] font-mont font-bold text-[28px] line-height[30.8px] text-black'>Thank you!</h2>
              <p tw='mb-[30px] font-mont font-normal text-[21px] line-height[30.45px] text-black'>
                Do you want to complete your registration now?
              </p>
              <div css={[CopyStyle, tw`mb-4 text-black`]}>Download the app</div>
              {/* App links */}
              <div tw="mb-10 lg:(mb-9)">
                vf
                {globalData.iosDownloadLink && (
                  <a
                    href={globalData.iosDownloadLink}
                    target="_blank"
                    rel="noreferrer"
                    tw="inline-block mr-2.5 w-[123px]"
                  >
                    <img src={appStore} />
                  </a>
                )}
                {globalData.androidDownloadLink && (
                  <a
                    href={globalData.androidDownloadLink}
                    target="_blank"
                    rel="noreferrer"
                    tw="inline-block w-[123px]"
                  >
                    <img src={googlePlay} />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankyouModal;